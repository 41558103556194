import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Col } from "react-flexbox-grid";

import {
  TextField,
  Select,
  MenuItem,
  Menu,
  IconButton,
  Radio,
} from "@material-ui/core";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import uploadIcon from "../../../../../assets/icons/orange-upload-icon.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import AppointMentDialog from "./appointment-dialog";
import NavigoAPIServices from "../services/navigoAPIServices";
import EventService from "../../../../../service/event/EventService";
import successCSVIcon from "../../../../../assets/icons/success-email.png";
import { GetStringSlot } from "../index";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import { errorMessageHandler } from "../utils/helperMethod";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import PatientTracking from "../../../../ops/modules/patients/modules/tracking/components/PatientTracking";
import TrackingService from "../../../../ops/modules/patients/modules/new-bridge/services/TrackingService";
import { MenuButton } from "react-md";
import { Modal } from "@material-ui/core";
const generalNavigoStatus = [
  // { value: 1, name: "In progress" },
];
const navigoLabtestStatus = [
  // { value: 4, name: "Confirmed" },
  { value: 5, name: "Scheduled" },
  { value: 6, name: "Reschedule Requested" },
  { value: 7, name: "Phlebo Assigned" },
  { value: 8, name: "Pickup" },
  { value: 9, name: "Sample Collected" },
  { value: 10, name: "Sample Received" },
  { value: 11, name: "Report Generated" },
  { value: 2, name: "Completed" },
];

const hraStatuses = [
  { value: 18, name: "Call later" },
  { value: 20, name: " Request to lab" },
  { value: 1, name: "In progress" },
  { value: 4, name: "Confirmed" },
  { value: 16, name: "Not answered" },
  { value: 17, name: "Not answered - 3 attempts" },
  { value: 19, name: "Not reachable" },
  { value: 15, name: "Not eligible" },
  { value: 2, name: "Completed" },
  { value: 12, name: "Successful HRA" },
  { value: 13, name: "Successful PRC" },
  { value: 14, name: "Declined" },
];

function NavigoAppointment(props) {
  const [searchStr, setSearchStr] = useState("");
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [appointList, setAppointList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [callNote, setCallNote] = useState(0);
  const [callNotePatient, setCallNotePatient] = useState({});
  const [componentMounted, setComponentMounted] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const trackingService = new TrackingService();
  const [patientId, setPatientId]= useState("");
  const [appointmentId, setAppointmentId]= useState("");
  const [countryCode, setCountryCode]= useState("91");
  const [phoneNumber, setPhoneNumber]= useState("");
  const [searchDisabled, setSearchDisabled] = useState(null);

  const handleClick = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(appointment);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchPlan = (id) => {
    let onResponse = (response) => {
      setPlanList(response);
    };
    let onError = (error) => {};
    trackingService.fetchPlan(id, onResponse, onError);
  };

  const [planList, setPlanList] = useState({});
  const [listType, setListType] = useState(1);
  const [updatingID, setUpdateID] = useState("");
  const [dialog, setDialog] = useState(false);
  const [dialogType, setDialogType] = useState(1);
  const [report, setFileReport] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [services, setServices] = useState([]);
  const [currentAppointment, setCurrentAppointment] = useState({});
  const [listStatus, setListStatus] = useState(4);
  const [isEdit, setIsEdit] = useState(false);
  const [noAppointment, setNoAppointment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedClientCode, setSelectedClientCode] = useState("All");
  const [clientCodeList, setClientCodeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [numFrom, setNumFrom] = useState(0);
  const [numTo, setNumTo] = useState(0);
  const [searchBy, setSearchBy] = useState("1");
  const [searchlist, setSearchlist] = useState("");
  const [assignedToFilter, setAssignedToFilter] = useState(false);
  const [clientCodeFilter, setClientCodeFilter] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState(false);
  const [creationDateRangeFilter, setCreationDateRangeFilter] = useState(false);
  const [vendorFilter, setVendorFilter] = useState(false);

  const [clientCodeFilterValue, setClientCodeFilterValue] = useState([]);
  const [assignedToFilterValue, setAssignedToFilterValue] = useState([]);
  const [vendorFilterValue, setVendorFilterValue] = useState([]);

  const [bookedForFilter, setBookedForFilter] = useState(false);
  const [bookedForValue, setBookedForValue] = useState(null); //family
  const [dateRangeFilterValueFrom, setDateRangeFilterValueFrom] =
    useState(null);
  const [dateRangeFilterValueTo, setDateRangeFilterValueTo] = useState(null);
  const [typeFilter, setTypeFilter] = useState(false);
  const [serviceTypeFilter, setServiceTypeFilter] = useState(false);
  const [creationDateRangeFilterValueFrom, setCreationDateRangeFilterFrom] =
    useState(null);
  const [creationDateRangeFilterValueTo, setCreationDateRangeFilterTo] =
    useState(null);
  const [typeFilterValue, setTypeFilterValue] = useState([]);
  const [serviceTypeFilterValue, setServiceTypeFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [packages, setPackages] = useState([]);
  const [vendorMap, setVendorMap] = useState({});
  const [newVendorSelected, setNewVendorSelected] = useState({});
  const [updatingVendor, setUpdatingVendor] = useState(false);
  const [fetchingVendors, setFetchingVendors] = useState(false);
  const [packageVendors, setPackageVendors] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [openFilter, setOpenFilter] = useState(0);
  const skip = useRef(30);
  const appointComplete = useRef(null);
  const appointValue = useRef(null);
  const fileURL = useRef(null);
  const allStatusList = useMemo(() => {
    let firstCount = false;
    const cancelArr = [{ value: 3, name: "Cancelled" }];
    const noShowArr = [{ value: 21, name: "No show" }];

    return generalNavigoStatus
      .concat(navigoLabtestStatus, hraStatuses, cancelArr, noShowArr)
      .filter((ele) => {
        if (ele.value === 4) {
          if (firstCount) {
            return false;
          }
          firstCount = true;
          return true;
        }
        return true;
      });
  }, []);

  const uploadFileRef = useRef(null);
  const uploadGenralRef = useRef(null);
  const emptyPatient = useRef({
    name: "",
    email: "",
    type: "",
    date: "",
    time: "",
    clientCode: "",
  });
  const dateRangeRef = useRef(null);
  const navigoAPIServices = new NavigoAPIServices();
  const authenticationService = new AuthenticationService();

  useEffect(() => {
    handleGetAllAppointments(1, 0, listStatus, selectedClientCode);
    handleGetAllServices();
    handleGetAllClientCode();
    handleGetAllCalendars();
    fetchAllVendors();
    fetchAllPackages();
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
    }
  }, [
    serviceTypeFilterValue,
    assignedToFilterValue,
    clientCodeFilterValue,
    typeFilterValue,
    statusFilterValue,
    bookedForValue,
    vendorFilterValue,
  ]);
  useEffect(() => {
    if (dateRangeFilterValueFrom && dateRangeFilterValueTo) {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
      setDateRangeFilter(true);
    }
  }, [dateRangeFilterValueFrom, dateRangeFilterValueTo]);
  // useEffect(() => {
  //   if (creationDateRangeFilterValueFrom && creationDateRangeFilterValueTo) {
  //     skip.current = 30;
  //     handleGetAllAppointments(1, 0, listStatus, "");
  //     setCreationDateRangeFilter(true);
  //   }
  // }, [creationDateRangeFilterValueFrom, creationDateRangeFilterValueTo]);
  useEffect(() => {
    if (creationDateRangeFilterValueFrom && creationDateRangeFilterValueTo) {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
      setCreationDateRangeFilter(true);
    }
  }, [creationDateRangeFilterValueFrom, creationDateRangeFilterValueTo]);
  useEffect(() => {
    if (searchlist === "" && componentMounted) {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
    }
    if (patientId === "") {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
    }
    if (appointmentId === "") {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
    }
    if (phoneNumber === "") {
      skip.current = 30;
      handleGetAllAppointments(1, 0, listStatus, "");
    }
  }, [searchlist]);

  const handleSelectChanges = (event) => {
    setSearchBy(event.target.value);
    setSearchlist("");
  };

  // const getPlaceholderText = () => {
  //   return searchBy === "1"
  //     ? "Search by patient Id"
  //     : "Search by appointment Id";
  // };

  const handleGetAllClientCode = () => {
    navigoAPIServices.getAllClientCode(
      (res) => {
        if (res) {
          setClientCodeList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );
  };
  const handleGetAllAppointments = (
    type,
    btnType = 0,
    status = 4,
    clientCode = "",
    dateFlag = true
  ) => {
    setLoading(true);
    let filter = {};
    let isFilter = false;
    if (clientCodeFilter) {
      // filter.client_code = [{ o: "=", v: clientCodeFilterValue.clientCode }];
      filter.client_code = []; // [{ o: "=", v: clientCodeFilterValue.clientCode }];
      if (clientCodeFilterValue.length > 0) {
        clientCodeFilterValue.forEach((filt) => {
          filter.client_code.push({ o: "=", v: filt.clientCode });
        });
      }
      isFilter = true;
    }
    if (vendorFilter) {
      // filter.client_code = [{ o: "=", v: clientCodeFilterValue.clientCode }];
      filter.internal_vendor_id = []; // [{ o: "=", v: clientCodeFilterValue.clientCode }];
      if (vendorFilterValue.length > 0) {
        vendorFilterValue.forEach((filt) => {
          filter.internal_vendor_id.push({ o: "=", v: filt.ID });
        });
      }
      isFilter = true;
    }
    if (typeFilter) {
      // filter["appointments.service_id"] = [{ o: "=", v: typeFilterValue.ID }];
      filter["appointments.service_id"] = [];
      if (typeFilterValue.length > 0) {
        typeFilterValue.forEach((filt) => {
          filter["appointments.service_id"].push({ o: "=", v: filt.ID });
        });
      }
      isFilter = true;
    }
    if (assignedToFilter) {
      // filter["appointments.email"] = [{ o: "=", v: assignedToFilterValue }];
      filter["appointments.email"] = [];
      if (assignedToFilterValue.length > 0) {
        assignedToFilterValue.forEach((filt) => {
          filter["appointments.email"].push({ o: "=", v: filt });
        });
      }
      isFilter = true;
    }

    if (searchlist) {
      filter[searchBy == "1" ? "appointments.patientID" : "appointments.ID"] = [
        { o: "=", v: searchlist },
      ];
      isFilter = true;
    }

    if(patientId) {
      console.log("aryan", patientId)
      filter["appointments.patientID"]=[
        {o:"=", v: patientId},
      ];
      isFilter = true;
    }


    if(appointmentId) {
      filter["appointments.ID"]=[
        {o:"=", v: appointmentId},
      ];
      isFilter = true;
    }

    if (phoneNumber) {
      filter["patientProfile.phoneno"] = [
        { o: "=", v: phoneNumber },
      ];
      filter["patientProfile.countrycode"] = [
        { o: "=", v: countryCode },
      ];
      filter["patientProfile.tenantid"]=[
        {o: "=", v: "1"},
      ];
      isFilter = true;
    }

    let cfromDate = "";
    let ctoDate = "";
    if (
      creationDateRangeFilterValueFrom &&
      creationDateRangeFilterValueTo &&
      dateFlag
    ) {
      cfromDate = creationDateRangeFilterValueFrom;
      ctoDate = creationDateRangeFilterValueTo;

      if (moment(ctoDate).isBefore(cfromDate)) {
        EventService.emit("showError", {
          message:
            "Please enter a valid range(to date must be greater than the from date)",
        });
        return;
      }
    }

    let fromDate = "";
    let toDate = "";
    if (dateRangeFilterValueFrom && dateRangeFilterValueTo && dateFlag) {
      fromDate = dateRangeFilterValueFrom;
      toDate = dateRangeFilterValueTo;

      if (moment(toDate).isBefore(fromDate)) {
        EventService.emit("showError", {
          message:
            "Please enter a valid range(to date must be greater than the from date)",
        });
        return;
      }
    }
    if (statusFilter) {
      filter["appointments.status"] = [];

      if (statusFilterValue.length > 0) {
        statusFilterValue.forEach((filt) => {
          if (filt.value === 5) {
            filter["appointments.status"].push({ o: "=", v: 0 });
          } else {
            filter["appointments.status"].push({ o: "=", v: filt.value });
          }
        });
      }

      isFilter = true;
    }

    let serviceType = "";
    if (serviceTypeFilterValue) {
      serviceType = serviceTypeFilterValue;
    }

    if (bookedForFilter) {
      filter["appointments.bookedFor"] = [];
      if (bookedForValue === "self") {
        filter["appointments.bookedFor"] = [{ o: "=", v: 0 }];
      }
      if (bookedForValue === "family") {
        filter["appointments.bookedFor"] = [{ o: "!=", v: 0 }];
      }
      isFilter = true;
    }
    // if (status !== 4) {
    //   filter["appointments.status"] = [{ o: "=", v: status }];

    //   isFilter = true;
    // }

    let counterFilter = 0;
    Object.keys(filter).forEach((ke) => {
      counterFilter = counterFilter + filter[ke].length;
    });
    if (counterFilter === 0) {
      isFilter = false;
    }
    navigoAPIServices.getAllAppointments(
      30,
      skip.current - 30,
      (res) => {
        if (res && res.appointments.length > 0) {
          let allList = [];

          allList = res.appointments.map((appoint) => {
            return { ...appoint };
          });

          let localNumFrom, localNumTo;
          if (type === 1) {
            localNumFrom = 1;
            localNumTo = Math.min(30, res.count);
          } else if (type === 2) {
            if (btnType === 0) {
              localNumTo = numFrom - 1;
              localNumFrom = Math.max(numFrom - 30, 1);
            } else {
              localNumFrom = numTo + 1;
              localNumTo = Math.min(numTo + 30, res.count);
            }
          } else if (type === 3) {
            localNumFrom = numFrom;
            localNumTo = numTo;
          }

          setAppointList([...allList]);
          setNoAppointment(false);
          setNumFrom(localNumFrom);
          setNumTo(localNumTo);
          setTotalCount(res.count);
          setLoading(false);
        } else {
          setAppointList([]);
          setNoAppointment(true);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        setNoAppointment(true);
        EventService.emit("showError", {
          message: "Cannot retrieve appointments",
        });
      },
      status,
      isFilter ? encodeURIComponent(JSON.stringify(filter)) : "",
      fromDate,
      toDate,
      serviceType,
      cfromDate,
      ctoDate
    );
  };
  const handleGetAllServices = () => {
    navigoAPIServices.getAllServices(
      (res) => {
        if (res && res.length > 0) {
          setServices([...res]);
        } else {
          EventService.emit("showError", {
            message: "No service found.",
          });
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "No service found.",
        });
      }
    );
  };
  const getStatusName = (i) => {
    if (i == 1) {
      return "In progress";
    } else if (i == 2) {
      return "Completed";
    } else if (i == 3) {
      return "Cancelled";
    } else if (i == 11) {
      return "Report Generated";
    } else if (i == 12) {
      return "Successful HRA";
    } else if (i == 13) {
      return "Successful PRC";
    } else if (i == 21) {
      return "No show";
    } else if (i == 14) {
      return "Declined";
    } else if (i == 15) {
      return "Not eligible";
    } else {
      return "Scheduled";
    }
  };

  const handleCompleteStatusFlow = (e, ele) => {
    appointValue.current = e.target.value;
    appointComplete.current = { ...ele };
    setDialogType(2);
    setDialog(2);
    setFileReport(false);
  };
  const handleSelectChange = (value, ele, fileUrl = "") => {
    setUpdateID(ele.appointment.ID);
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    let userName = `${fName} ${lName ? lName : ""}`;
    let updatedBody = {
      appointmentID: ele.appointment.ID,
      status: value,
      reportURL: "",
      author: {
        name: userName,
        employeeId: user?.employeeProfile?.id,
      },
    };

    navigoAPIServices.updateAppointmentStatus(
      updatedBody,
      (res) => {
        let filteredList = appointList.map((appoint) => {
          if (ele.appointment.ID === appoint.appointment.ID) {
            return {
              ...appoint,
              appointment: { ...appoint.appointment, status: value },
            };
          }

          return { ...appoint };
        });
        fileURL.current = "";
        setAppointList([...filteredList]);
        setUpdateID("");
        setDialog(false);
      },
      (err) => {
        setUpdateID("");
        setAppointList((currentList) => {
          return [...currentList];
        });
        setDialog(false);
        let errMsg = errorMessageHandler(
          err,
          "Something went wrong while updating status."
        );

        EventService.emit("showError", {
          message: errMsg,
        });
      }
    );
  };

  const labtestAutomation = (res) => {
    navigoAPIServices.uploadDocumentLabtestAutomation(
      {
        patientId: res.patientId,
        documentURL: res.url,
      },
      (res3) => {},
      (err3) => {
        console.log("pr- Error in Labtest Automation api", err3);
      }
    );
  };
  const getDocumentFormat = (type) => {
    switch (type) {
      case "jpg":
      case "jpeg":
        return 1;
      case "png":
        return 2;
      case "gif":
        return 3;
      case "pdf":
        return 4;
      case "mp3":
        return 5;
      case "mp4":
        return 6;
      default:
        return 0;
    }
  };

  const handleUploadReportClick = () => {
    // appointValue.current = menuFor.appointment.ID;
    appointComplete.current = { ...menuFor };
    setFileReport(false);
    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleUploadFile = (e) => {
    if (e.target.files.length === 0) return;
    setUploading(true);
    setDialogType(4);
    let document = {
      title: "",
      description: "",
      url: "",
      type: 11,
      format: getDocumentFormat(e.target.files[0].name.split(".").pop()),
      status: 4,
    };
    if (appointComplete.current.appointment.bookedFor != 0) {
      let body = {
        ...document,
      };
      document.type = 6;
      navigoAPIServices.uploadDocument(
        e.target.files[0],
        e.target.files[0].name,
        body,
        (res) => {
          fileURL.current = res.url;
          setFileReport(true);
          setDialogType(2);
          navigoAPIServices.uploadDocumentDigital(
            {
              appointmentId: menuFor.appointment.ID,
              documentId: res.id,
            },
            (res2) => {
              getDocumentByAppointment(menuFor.appointment.ID);
              setUploading(false);
              EventService.emit("showError", {
                message: "Document uploaded",
              });
            }
          );
          labtestAutomation(res);
        },
        (err) => {
          setDialog(false);
          setDialogType(2);
          setUploading(false);
          EventService.emit("showError", {
            message: "Something went wrong while uploading report.",
          });
        },
        appointComplete.current.appointment.bookedFor
      );
    }
    navigoAPIServices.uploadDocument(
      e.target.files[0],
      e.target.files[0].name,
      document,
      (res) => {
        fileURL.current = res.url;
        setFileReport(true);
        setDialogType(2);
        navigoAPIServices.uploadDocumentDigital(
          {
            appointmentId: menuFor.appointment.ID,
            documentId: res.id,
          },
          (res2) => {
            getDocumentByAppointment(menuFor.appointment.ID);
            setUploading(false);
            EventService.emit("showError", {
              message: "Document uploaded",
            });
          }
        );
        labtestAutomation(res);
      },
      (err) => {
        setDialog(false);
        setDialogType(2);
        setUploading(false);
        EventService.emit("showError", {
          message: "Something went wrong while uploading report.",
        });
      },
      appointComplete.current.patient.id
    );
  };

  const handleCloseDialog = (arg) => {
    if (arg) {
      handleGetAllAppointments(3, 0, listStatus, selectedClientCode);
    }
    setDialog(false);
    setIsEdit(false);
    setDialogType(0);
  };

  const searchPatientId = (e) => {
    setSearchDisabled("patientId");
    skip.current = 30;
    handleGetAllAppointments(1, 0, listStatus, "");
  };

  const searchAppointmentId =(e)=>{
    setSearchDisabled("appointmentId");
    skip.current = 30;
    handleGetAllAppointments(1, 0, listStatus, "");
  }

  const searchPhoneNumber = (e)=>{
    setSearchDisabled("phoneNumber");
    skip.current = 30;
    handleGetAllAppointments(1, 0, listStatus, "");
  }

  const getAppointmentListByAppointment = (e) => {
    skip.current = 30;
    handleGetAllAppointments(1, 0, listStatus, "");
  };

  const handleAddNewAppointment = () => {
    setDialogType(1);
    setDialog(true);
  };

  const handleEditAppointment = (ele) => {
    setCurrentAppointment({ ...ele });
    setDialogType(1);
    setIsEdit(true);
    setDialog(true);
  };
  const handleMarkComplete = () => {
    handleSelectChange(
      appointValue.current,
      appointComplete.current,
      fileURL.current ? fileURL.current : ""
    );
    setDialog(false);
  };

  const handlePrevNextAppoint = (arg) => {
    if (arg) {
      skip.current = skip.current + 30;
      handleGetAllAppointments(2, 1, listStatus, selectedClientCode);
    } else {
      skip.current = skip.current - 30;
      handleGetAllAppointments(2, 0, listStatus, selectedClientCode);
    }
  };
  const handleGetAllCalendars = () => {
    navigoAPIServices.getAllCalendars(
      (res) => {
        if (res && res.length > 0) {
          setCalendarList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );
  };
  const handleCSVUpload = () => {
    if (uploadGenralRef && uploadGenralRef.current) {
      uploadGenralRef.current.click();
    }
  };

  const saveAndUploadAppointCSV = (e) => {
    try {
      setDialog(true);
      setDialogType(4);

      let body = new FormData();
      body.append("file", e.target.files[0]);
      e.target.value = null;
      navigoAPIServices.uploadAppointCSV(
        body,
        (res) => {
          const blob = new Blob([res]);
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "report.csv";
          document.body.appendChild(a);
          a.click();

          setDialog(false);
        },
        (err) => {
          setDialog(false);
          EventService.emit("showError", {
            message: "Error while uploading csv file",
          });
        }
      );
    } catch (err) {
      setDialog(false);
      EventService.emit("showError", {
        message: "Error while uploading csv file",
      });
    }
  };

  const fetchAllPackages = () => {
    navigoAPIServices.getAllPackages(
      (res) => {
        if (res && res.length > 0) {
          const allPackages = res.map((ele) => {
            return { ...ele };
          });

          setPackages(allPackages);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Error while fetching vendors list.",
        });
      }
    );
  };
  const fetchAllVendors = () => {
    navigoAPIServices.getAllVendors(
      (res) => {
        let allVendors = [];
        let newVendorMap = {};
        if (res && res.length > 0) {
          allVendors = res.map((ele) => {
            newVendorMap[ele.ID] = ele.name;
            return { ...ele };
          });
        }
        setVendors(allVendors);
        setVendorMap(newVendorMap);
      },
      (err) => {
        EventService.emit("showError", {
          message: "Error while fetching vendors list.",
        });
      }
    );
  };

  const fetchVendorPackages = (id) => {
    setFetchingVendors(true);
    navigoAPIServices.getAllPackagesVendor(
      id,
      (res) => {
        if (res && res.vendor_list && res.vendor_list.length > 0) {
          const allVendors = res.vendor_list.filter((ele) => {
            return ele.ID !== currentAppointment.interalVendorId;
          });
          setFetchingVendors(false);

          setPackageVendors(allVendors);
        } else {
          setFetchingVendors(false);
          setPackageVendors([]);
          EventService.emit("showError", {
            message: "No corresponding vendor found",
          });
        }
      },
      (err) => {
        setFetchingVendors(false);
        EventService.emit("showError", {
          message: err ? err : "Error while fetching vendors list.",
        });
      }
    );
  };

  const handleUpdateVendorId = () => {
    setUpdatingVendor(true);
    navigoAPIServices.updatePackageId(
      {
        appointmentId: currentAppointment.ID,
        vendorId: parseInt(newVendorSelected.ID),
        packageId: parseInt(currentAppointment.packageId),
      },
      (res) => {
        handleVendorDialog(true);
        setAppointList((prevList) => {
          return prevList.map((ele) => {
            if (ele.appointment.ID === currentAppointment.ID) {
              return { ...ele, appointment: { ...res } };
            }
            return { ...ele };
          });
        });
        // handleGetAllAppointments();
        EventService.emit("showError", {
          message: "Appointment vendor updated successfully",
        });
      },
      (err) => {
        setUpdatingVendor(true);
        EventService.emit("showError", {
          message: "Error while updating vendor.",
        });
      }
    );
  };

  const handleVendorDialog = (isClose, ele) => {
    if (isClose) {
      setDialog(false);
      setDialogType(0);
      setNewVendorSelected({});
      setUpdatingVendor(false);
      setCurrentAppointment({});
    } else {
      fetchVendorPackages(ele.packageId);
      setNewVendorSelected({});
      setCurrentAppointment({ ...ele });
      setDialogType(6);
      setDialog(true);
      setAnchorEl(null);
    }
  };

  const getDocumentByAppointment = (id) => {
    navigoAPIServices.getDocumentByAppointment(
      id,
      (res) => {
        setResponseData(res.documents);
      },
      (err) => {}
    );
  };

  const closeModal = () => {
    setResponseData([]);
    setOpenModal(false);
  };

  const viewDocument = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };


  const handleInputChange = (setter, value, field) => {
    setter(value); 
    
    if (!value) {
      if (field === "patientId") {
        setPatientId("");
      } else if (field === "appointmentId") {
        setAppointmentId("");
      } else if (field === "phoneNumber") {
        setPhoneNumber("");
      }
      if (searchDisabled === field) {
        setSearchDisabled(null);
      }
    }
  };

  useEffect(() => {
    if (!patientId && !appointmentId && !phoneNumber) {
      handleGetAllAppointments(1, 0, listStatus, "");
    }
  }, [patientId, appointmentId, phoneNumber]); 
  
  const excludedValues = new Set([2, 3, 11, 12, 13]);

  return (
    <div className="navigo-appoint-container">
      <div className="navigo-appoint-section-3">
        <div className="navigo-appoint-section-1" onClick={props.changeLayout}>
          <FontIcon>arrow_back</FontIcon>
        </div>
        <h1 className="navigo-appoint-section-2">Appointments</h1>

        {/* <div className="navigo-appoint-section-4">
          <div className="navigo-appoint-section-4-bottom">
            <div className="navigo-appoint-section-4-bottom-mid">
              <FontIcon className="navigo-appoint-section-4-bottom-icon">
                search
              </FontIcon>
              <div className="navigo-appoint-section-4-input-container">
                <Select
                  className="navigo-appoint-section-4-select navigo-dashboard-select-css"
                  value={searchBy}
                  onChange={handleSelectChanges}
                >
                  <MenuItem value="1">Patient Id</MenuItem>
                  <MenuItem value="2">Appointment Id</MenuItem>
                </Select>
                <TextField
                  type="number"
                  className="navigo-appoint-section-4-autocomplete-input"
                  placeholder={getPlaceholderText()}
                  value={searchlist}
                  onChange={(event) => setSearchlist(event.target.value)}
                />
              </div>
            </div>

            <button
              className="navigo-appoint-section-4-bottom-button"
              disabled={searchlist ? false : true}
              onClick={() => {
                getAppointmentListByPatient(searchlist);
              }}
            >
              Search
            </button>
          </div>
        </div> */}
        <div className="navigo-appoint-section-4">
          <div className="navigo-appoint-section-4-bottom">
            <div className="navigo-appoint-section-4-field">
              <TextField
                type="number"
                className="navigo-appoint-section-4-textfield"
                placeholder="Patient ID"
                value={patientId}
                onChange={(event) => handleInputChange(setPatientId, event.target.value, "patientId")}
                disabled={searchDisabled && searchDisabled !== "patientId"}
              />
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={!patientId || (searchDisabled && searchDisabled !== "patientId")}
                onClick={() => searchPatientId(patientId)}
              >
                Search
              </button>
            </div>

            <div className="navigo-appoint-section-4-field">
              <TextField
                type="number"
                className="navigo-appoint-section-4-textfield"
                placeholder="Appointment ID"
                value={appointmentId}
                onChange={(event) => handleInputChange(setAppointmentId, event.target.value, "appointmentId")}
                disabled={searchDisabled && searchDisabled !== "appointmentId"}
              />
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={!appointmentId || (searchDisabled && searchDisabled !== "appointmentId")}
                onClick={() => searchAppointmentId(appointmentId)}
              >
                Search
              </button>
            </div>

            <div className="navigo-appoint-section-4-field">
              <div className="navigo-phone-number-field">
                <Select
                  value={countryCode}
                  placeholder="+91"
                  onChange={(event) => setCountryCode(event.target.value)}
                  className="navigo-country-code-select"
                  disabled={searchDisabled && searchDisabled !== "phoneNumber"} 
                >
                  <MenuItem value="91">+91</MenuItem>
                  <MenuItem value="63">+63</MenuItem>
                </Select>
                <TextField
                  type="tel" 
                  className="navigo-phone-number-input"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(event) => handleInputChange(setPhoneNumber, event.target.value, "phoneNumber")}
                  inputProps={{ maxLength: 10 }}
                  disabled={searchDisabled && searchDisabled !== "phoneNumber"}
                />
              </div>
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={phoneNumber.length !== 10 || (searchDisabled && searchDisabled !== "phoneNumber")}
                onClick={() => searchPhoneNumber(phoneNumber)}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="navigo-appoint-section-4-bottom-button-container">
          <button
            className="navigo-appoint-section-4-bottom-button"
            onClick={handleAddNewAppointment}
          >
            + Add New
          </button>
          <button
            className="navigo-appoint-section-4-bottom-button"
            onClick={handleCSVUpload}
          >
            Upload CSV
          </button>
        </div>
      </div>

      <div className="navigo-appoint-section-5">
        <div className="navigo-appoint-section-5-grid-container">
        <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Phone <br />
              no.
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Appointment <br />
              Id
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Patient <br />
              Id
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Patient <br />
              name
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section">
            <p>
              Service <br />
              Type
              <br />
            </p>
            <img
              src={serviceTypeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(9);
              }}
            />
            {openFilter === 9 && (
              <div className="service-type-filter">
                {/* <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={serviceTypes}
                  options={services}
                  value={serviceTypeFilterValue} //
                  onChange={(v) => {
                    if (v.target.value) {
                      setServiceTypeFilterValue(v);
                      console.log("hello",v);
                      setServiceTypeFilter(true);
                    } else {
                      setServiceTypeFilterValue([]);
                      setServiceTypeFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.type;
                  }}
                  filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Enter the service type"}
                    />
                  )}
                /> */}
                <div>
                  <Radio
                    label="HRA"
                    checked={serviceTypeFilterValue === "HRA"}
                    onClick={() => {
                      setServiceTypeFilterValue("HRA");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">HRA</label>
                </div>
                <div>
                  <Radio
                    label="PRC"
                    checked={serviceTypeFilterValue === "PRC"}
                    onClick={() => {
                      setServiceTypeFilterValue("PRC");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">PRC</label>
                </div>
                <div>
                  <Radio
                    label="Physiotherapy consultation"
                    checked={
                      serviceTypeFilterValue === "Physiotherapy consultation"
                    }
                    onClick={() => {
                      setServiceTypeFilterValue("Physiotherapy consultation");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">
                    Physiotherapy consultation
                  </label>
                </div>
                <div>
                  <Radio
                    label="Psychologist consultation"
                    checked={
                      serviceTypeFilterValue === "Psychologist consultation"
                    }
                    onClick={() => {
                      setServiceTypeFilterValue("Psychologist consultation");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">
                    Psychologist consultation
                  </label>
                </div>
                <div>
                  <Radio
                    label="Doctor consultation"
                    checked={serviceTypeFilterValue === "Doctor consultation"}
                    onClick={() => {
                      setServiceTypeFilterValue("Doctor consultation");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">Doctor consultation</label>
                </div>
                <div>
                  <Radio
                    label="Pathology test"
                    checked={serviceTypeFilterValue === "Pathology test"}
                    onClick={() => {
                      setServiceTypeFilterValue("Pathology test");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">Pathology test</label>
                </div>
                <div>
                  <Radio
                    label="Radiology test"
                    checked={serviceTypeFilterValue === "Radiology test"}
                    onClick={() => {
                      setServiceTypeFilterValue("Radiology test");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">Radiology test</label>
                </div>
                <div>
                  <Radio
                    label="Other"
                    checked={serviceTypeFilterValue === "Other"}
                    onClick={() => {
                      setServiceTypeFilterValue("Other");
                      setServiceTypeFilter(true);
                    }}
                  />
                  <label className="label-radio-btn">Other</label>
                </div>
                <Button
                  className="clear-navigo-booked-for"
                  onClick={() => {
                    setServiceTypeFilterValue(null);
                    setServiceTypeFilter(false);
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Service <br />
              name
            </p>
            <img
              src={typeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(1);
                // setOpenTypeFilter(true);
              }}
            />
            {openFilter === 1 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={services}
                  value={typeFilterValue} //
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setTypeFilterValue(v);
                      setTypeFilter(true);
                    } else {
                      setTypeFilterValue([]);
                      setTypeFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Enter the service name"}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Creation <br />
              Date
            </p>
            <img
              src={creationDateRangeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(8);
                // setOpenDateRangeFilter(true);
              }}
            />
            {openFilter === 8 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <div className="navigo-appointment-date-range-filter ">
                  <p>From</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    onChange={(v) => {
                      if (v.target.value) {
                        setCreationDateRangeFilterFrom(v.target.value);
                      }
                    }}
                    value={creationDateRangeFilterValueFrom}
                  ></TextField>
                </div>
                <div className="navigo-appointment-date-range-filter ">
                  <p>To</p>
                  <TextField
                    type="date"
                    defaultValue={""}
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    onChange={(v) => {
                      if (v.target.value) {
                        setCreationDateRangeFilterTo(v.target.value);
                      }
                    }}
                    value={creationDateRangeFilterValueTo}
                  ></TextField>
                </div>

                <Button
                  className=""
                  onClick={() => {
                    setCreationDateRangeFilterFrom("");
                    setCreationDateRangeFilterTo("");
                    setCreationDateRangeFilter(false);
                    handleGetAllAppointments(1, 0, listStatus, "", false);
                  }}
                >
                  <FontIcon className="">close</FontIcon>
                </Button>
              </div>
            )}
          </div>

          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Appointment <br />
              Date
            </p>
            <img
              src={dateRangeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(2);
                // setOpenDateRangeFilter(true);
              }}
            />
            {openFilter === 2 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <div className="navigo-appointment-date-range-filter ">
                  <p>From</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    onChange={(v) => {
                      if (v.target.value) {
                        setDateRangeFilterValueFrom(v.target.value);
                      }
                    }}
                    value={dateRangeFilterValueFrom}
                  ></TextField>
                </div>
                <div className="navigo-appointment-date-range-filter ">
                  <p>To</p>
                  <TextField
                    type="date"
                    defaultValue={""}
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    onChange={(v) => {
                      if (v.target.value) {
                        setDateRangeFilterValueTo(v.target.value);
                      }
                    }}
                    value={dateRangeFilterValueTo}
                  ></TextField>
                </div>

                <Button
                  className=""
                  onClick={() => {
                    setDateRangeFilterValueFrom("");
                    setDateRangeFilterValueTo("");
                    setDateRangeFilter(false);
                    handleGetAllAppointments(1, 0, listStatus, "", false);
                  }}
                >
                  <FontIcon className="">close</FontIcon>
                </Button>
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>Timeslot</p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Assigned <br />
              to
            </p>
            <img
              src={assignedToFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(3);
                // setOpenAssignedToFilter(true);
              }}
            />
            {openFilter === 3 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={calendarList}
                  value={assignedToFilterValue} //assignedToFilterValue
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setAssignedToFilterValue(v);
                      setAssignedToFilter(true);
                    } else {
                      setAssignedToFilterValue([]);
                      setAssignedToFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Enter the email Id"} />
                  )}
                />
              </div>
            )}
          </div>

          <div className="navigo-appoint-section-5-grid-container-section-7 navigo-appoint-section-5-grid-container-common-section">
            <p>
              Client <br />
              code
            </p>
            <img
              src={clientCodeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(4);
                // setOpenClientCodeFilter(true);
              }}
            />
            {openFilter === 4 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={clientCodeList}
                  value={clientCodeFilterValue} //clientCodeFilterValue
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setClientCodeFilterValue(v);
                      setClientCodeFilter(true);
                    } else {
                      setClientCodeFilterValue([]);
                      setClientCodeFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.clientName + " (" + option.clientCode + ")";
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Enter the client code to filter"}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-section-7 navigo-appoint-section-5-grid-container-common-section">
            <p>Vendor</p>
            <img
              src={vendorFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(7);
                // setOpenClientCodeFilter(true);
              }}
            />
            {openFilter === 7 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={vendors}
                  value={vendorFilterValue} //clientCodeFilterValue
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setVendorFilterValue(v);
                      setVendorFilter(true);
                    } else {
                      setVendorFilterValue([]);
                      setVendorFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Enter the vendor"} />
                  )}
                />
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>Status</p>
            <img
              src={statusFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(5);
                // setOpenStatusFilter(true);
              }}
            />
            {openFilter === 5 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={allStatusList}
                  value={statusFilterValue} //statusFilterValue
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setStatusFilterValue(v);
                      setStatusFilter(true);
                    } else {
                      setStatusFilterValue([]);
                      setStatusFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Type status name"} />
                  )}
                />
              </div>
            )}
          </div>
          <div></div>
        </div>
      </div>
      <div className="navigo-appoint-section-6">
        {appointList && appointList.length > 0 && (
          <div className="navigo-appoint-bottom-bar">
            <Button
              className="navigo-appoint-bottom-bar-button"
              onClick={() => {
                handlePrevNextAppoint(0);
              }}
              disabled={numFrom === 1 || loading}
            >
              <FontIcon className="navigo-count-icon">arrow_drop_up</FontIcon>
            </Button>
            <div className="navigo-appoint-bottom-bar-text">
              Showing
              <span
                style={{
                  color: "#ef7c01",
                }}
              >
                {` ${numFrom}-${numTo} `}
              </span>
              <span>of</span>
              <span>{` ${totalCount}`}</span>
            </div>
            <Button
              className="navigo-appoint-bottom-bar-button"
              onClick={() => {
                handlePrevNextAppoint(1);
              }}
              disabled={numTo === totalCount || loading}
            >
              <FontIcon className="navigo-count-icon">arrow_drop_down</FontIcon>
            </Button>
          </div>
        )}
        <div className="navigo-appoint-section-6-list">
          {appointList &&
            appointList.map((ele) => {
              return (
                <div
                  className="navigo-appoint-section-6-grid-container"
                  key={ele.appointment.ID}
                  style={{
                    backgroundColor: `${
                      ele.appointment.bookedFor === 0 ? "white" : "#F1F3F5"
                    }`,
                  }}
                >
                  <div className="general-font-class">
                    {`+${ele.patient.countryCode}-${ele.patient.phoneno}`}
                  </div>
                  <div className="general-font-class ">
                    {ele.appointment.ID}
                  </div>
                  <div className="general-font-class ">
                    {ele.appointment.bookedFor === 0
                      ? ele.patient.id
                      : ele.appointment.bookedFor}
                  </div>
                  <a
                    className="general-font-class general-cursor-pointer navigo-appointment-break-word-class"
                    href={`/patients/${
                      ele.appointment.bookedFor === 0
                        ? ele.patient.id
                        : ele.appointment.bookedFor
                    }/new-bridge`}
                    target="_blank"
                  >
                    {ele.appointment.bookedFor === 0
                      ? ele.patient?.firstName +
                        " " +
                        (ele.patient?.lastName || "")
                      : `${ele.bookedForPatient?.firstName}(${ele.patient?.firstName})`}
                  </a>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {ele.service.type}
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {ele.service.name}
                  </div>
                  <div className="general-font-class">
                    {moment(ele.appointment.CreatedAt).format("DD/MM/YYYY")}
                  </div>
                  <div className="general-font-class">
                    {moment(ele.appointment.appointmentDate).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                  <div className="general-font-class">
                    {GetStringSlot(ele.appointment.timeSlot)}
                  </div>
                  <div className="general-font-class">
                    {ele.appointment.email}
                  </div>
                  <div className="general-font-class">
                    {ele.appointment.bookedFor === 0
                      ? ele.patient.clientCode
                      : ele.bookedForPatient?.clientCode}
                  </div>
                  <div className="general-font-class">
                    {ele.appointment.vendorCode !== ""
                      ? ele.appointment.vendorCode
                      : `-`}
                  </div>
                  <div className="navigo-appoint-section-6-grid-status">
                    {ele.appointment.status !== 2 &&
                    ele.appointment.status !== 21 &&
                    ele.appointment.status !== 15 &&
                    ele.appointment.status !== 14 &&
                    ele.appointment.status !== 3 &&
                    ele.appointment.status !== 11 &&
                    ele.appointment.status !== 12 &&
                    ele.appointment.status !== 13 ? (
                      <Select
                        disabled={
                          updatingID == ele.appointment.ID ? true : false
                        }
                        labelId="navigo-appoint-status-select"
                        id="navigo-appoint-status-select-id"
                        value={ele.appointment.status}
                        onChange={(e) => {
                          if (e.target.value === 2 || e.target.value === 11) {
                            handleCompleteStatusFlow(e, ele);
                          } else {
                            handleSelectChange(e.target.value, ele);
                          }
                        }}
                        label="Status"
                        className={`navigo-dashboard-select-css navigo-status-selector ${
                          ele.appointment.status === 2
                            ? "progress-class"
                            : `${
                                ele.appointment.status === 3
                                  ? "closed-class"
                                  : `${
                                      ele.appointment.status === 4
                                        ? "invalid-class"
                                        : ele.appointment.status === 1
                                        ? "pending-class"
                                        : "progress-class"
                                    }`
                              }`
                        }`}
                      >
                        {!ele.service.isLabTest && (
                          <MenuItem
                            value={0}
                            disabled={
                              ele.appointment.status == 2 ||
                              ele.appointment.status === 6
                                ? true
                                : false
                            }
                            style={{
                              borderBottom: "1px solid #d8d8d8",
                              fontWeight: "600",
                              paddingLeft: "5px",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Scheduled
                          </MenuItem>
                        )}

                        {!ele.service.isLabTest &&
                          hraStatuses.map((st) => (
                            <MenuItem
                              value={st.value}
                              style={{
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                                fontWeight: "600",
                                paddingLeft: "5px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {st.name}
                            </MenuItem>
                          ))}
                        {ele.service.isLabTest &&
                          navigoLabtestStatus.map((st) => {
                            return (
                              <MenuItem
                                key={st.value}
                                value={st.value}
                                disabled={st.value == 6}
                                style={{
                                  borderBottomLeftRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                  fontWeight: "600",
                                  paddingLeft: "5px",
                                  fontFamily: "Montserrat",
                                  borderBottom: "1px solid #d8d8d8",
                                  display: `${
                                    st.value == 6 ? "none" : "block"
                                  }`,
                                }}
                                className="general-navigo-status-select-class"
                              >
                                {st.name}
                              </MenuItem>
                            );
                          })}
                        <MenuItem
                          value={21}
                          style={{
                            borderBottom: "1px solid #d8d8d8",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          disabled={ele.appointment.status === 6 ? true : false}
                        >
                          No show
                        </MenuItem>
                        <MenuItem
                          value={3}
                          style={{
                            borderBottom: "1px solid #d8d8d8",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          disabled={ele.appointment.status === 6 ? true : false}
                        >
                          Cancelled
                        </MenuItem>
                      </Select>
                    ) : (
                      <div
                        className={`${
                          ele.status === 4
                            ? "navigo-appoint-non-select-status-invalid"
                            : "navigo-appoint-non-select-status-closed"
                        } `}
                      >
                        <span>{getStatusName(ele.appointment.status)}</span>
                      </div>
                    )}
                  </div>
                  <IconButton
                    onClick={(e) => handleClick(e, ele)}
                    className="navigo-appoint-section-6-grid-edit-call"
                  >
                    <OptionsIcon />
                  </IconButton>
                </div>
              );
            })}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {!excludedValues.has(menuFor?.appointment?.status) && <MenuItem
              onClick={() => {
                handleEditAppointment(menuFor);
                setAnchorEl(null);
              }}
            >
              Edit appointment
            </MenuItem>}
            <MenuItem
              onClick={() => {
                setCallNote(menuFor.appointment.patientID);
                fetchPlan(menuFor.appointment.patientID);
                setCallNotePatient(menuFor.patient);
                setDialog(true);
                setDialogType(5);
                setAnchorEl(null);
              }}
            >
              Add callnote
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenModal(true);
                getDocumentByAppointment(menuFor.appointment.ID);
                setAnchorEl(null);
              }}
            >
              Upload document
            </MenuItem>
            {menuFor && menuFor.service && !menuFor.service.isLabTest && (
              <MenuItem
                onClick={() => {
                  handleVendorDialog(false, menuFor.appointment);
                }}
              >
                Edit Vendor
              </MenuItem>
            )}
          </Menu>
        </div>
        {loading && <GlobalSkeletonLoader />}
        {loading === false && noAppointment && (
          <div className="no-appointments-container">
            <p>No appointments found!</p>
          </div>
        )}
      </div>
      {dialog && (
        <div className="navigo-dialog-container">
          {dialogType === 1 && (
            <AppointMentDialog
              {...emptyPatient.current}
              isEdit={isEdit}
              currentAppointment={currentAppointment}
              services={services}
              closeDialog={handleCloseDialog}
              handleSelectChange={handleSelectChange}
              calendarList={calendarList}
              vendors={vendors}
            />
          )}
          {dialogType === 2 && handleMarkComplete()}
          {dialogType === 4 && (
            <div className="navigo-appoint-upload-dialog-loading">
              <p>Uploading....</p>
            </div>
          )}
          {dialogType === 5 && (
            <div className="navigo-call-note">
              <FontIcon
                className="navigo-appoint-upload-close"
                style={{ cursor: "pointer" }}
                onClick={handleCloseDialog}
              >
                close
              </FontIcon>
              <Col xs={12} sm={12} md={12} lg={12}>
                <hr />
                <PatientTracking
                  navigo={true}
                  patient={callNotePatient}
                  patientId={callNote}
                  fromNewBridge={true}
                  planList={planList}
                />
              </Col>
            </div>
          )}

          {dialogType === 6 && (
            <div className="navigo-change-package-container">
              <div className="navigo-change-package-container-top">
                <p>Edit vendor</p>
                <FontIcon
                  className="navigo-appoint-upload-close"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleVendorDialog(true)}
                >
                  close
                </FontIcon>
              </div>
              <div className="navigo-change-package-container-mid-bottom">
                <p>Current Vendor</p>
                <Autocomplete
                  value={vendorMap[currentAppointment.interalVendorId]}
                  disabled={true}
                  options={vendors}
                  noOptionsText={""}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        vendorMap[currentAppointment.interalVendorId]
                      }
                      className="autocomplete-text-field-class"
                    />
                  )}
                />
              </div>
              <div className="navigo-change-package-container-mid-bottom">
                <p>New Vendor </p>
                <Autocomplete
                  value={newVendorSelected}
                  onChange={(e, v) => {
                    if (v) {
                      setNewVendorSelected(v);
                    }
                  }}
                  disabled={fetchingVendors || updatingVendor}
                  options={packageVendors}
                  noOptionsText={""}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        fetchingVendors
                          ? "Fetching vendors..."
                          : "Search Vendors"
                      }
                      className="autocomplete-text-field-class"
                    />
                  )}
                />
              </div>
              <button
                className="general-orange-save-button"
                disabled={updatingVendor}
                onClick={handleUpdateVendorId}
              >
                Save
              </button>
            </div>
          )}
        </div>
      )}
      {openBackdrop && (
        <div
          className="navigo-appointment-backdrop-class"
          onClick={() => {
            setOpenFilter(0);
            setOpenBackdrop(false);
          }}
        ></div>
      )}
      <input
        type="file"
        style={{ display: "none" }}
        ref={uploadGenralRef}
        accept={"text/csv"}
        onChange={(e) => {
          saveAndUploadAppointCSV(e);
        }}
      />
      {openModal && (
        <Modal open={openModal} onClose={closeModal}>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width: "80%",
                maxWidth: "800px",
                position: "relative",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <h2>Upload document</h2>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 style={{ marginRight: "40px" }}>Document ID</h6>
                <h6 style={{ marginRight: "80px" }}>Upload date</h6>
                <h6>Document name</h6>
              </div>
              {responseData.map((x) => (
                <div
                  key={x.id}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginRight: "90px" }}>{x.id}</p>
                  <p style={{ marginRight: "auto" }}>
                    {new Date(x.createdAt).toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                  <p
                    style={{
                      flex: "1",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "90px",
                      marginLeft: "90px",
                    }}
                  >
                    {x.title}
                  </p>
                  <button
                    onClick={() => viewDocument(x.url)}
                    style={{
                      backgroundColor: "#00c9d3",
                      color: "white",
                      padding: "9px 15px",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      width: "200px",
                      fontSize: "15px",
                      height: "40px",
                    }}
                  >
                    View document
                  </button>
                </div>
              ))}
              <div className="modal-content">
                <button
                  className="navigo-appoint-upload-dialog-button-1"
                  onClick={handleUploadReportClick}
                  disabled={uploading}
                  style={{
                    backgroundColor: uploading ? "#d8d8d8" : "#ef7c01",
                    color: "white",
                    border: "none",
                    outline: "none",
                    position: "relative",
                    cursor: "pointer",
                    padding: "10px",
                    fontSize: "20px",
                    marginTop: "20px",
                    width: "100%",
                    maxWidth: "800px",

                    height: "60px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {!uploading && report === false && (
                    <div className="navigo-appoint-upload-dialog-button-1-layout-1">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span>Upload a new document</span>
                    </div>
                  )}
                  {uploading && <div>Uploading...</div>}
                  {!uploading && report === true && (
                    <div className="navigo-appoint-upload-dialog-button-1-layout-1">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span>Upload a new document</span>
                    </div>
                  )}
                </button>
                <input
                  type="file"
                  hidden
                  accept={"application/pdf,image/png,image/jpeg,image/jpg"}
                  ref={uploadFileRef}
                  onChange={handleUploadFile}
                />
                <button
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "53px",
                    height: "53px",
                    borderRadius: "70%",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  onClick={closeModal}
                >
                  X
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default NavigoAppointment;
